<template>
  <b-tabs content-class="pt-1">
    <b-tab active title="Tersedia">
      <Tersedia />
    </b-tab>

    <b-tab title="Ebook Saya">
      <EbookSaya />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import Tersedia from "./components/Tersedia";
import EbookSaya from "./components/EbookSaya";

export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    Tersedia,
    EbookSaya,
  },
};
</script>
